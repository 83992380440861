import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => {
        return e instanceof NavigationEnd;
      })
    ).subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.history.push(e.urlAfterRedirects);
      }
    });
  }

  goBack() {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  get canGoBack() {
    return this.history.length > 1;
  }
}
